<template>
  <div class="body fixed mobile">
    <!-- Loading Spinner -->    
    <!-- Header -->
    <section class="header">
      <div class="page-title-wrap">
          <a  @click.prevent="$router.push('/home')"  class="back-page"></a>
          <div class="page-title">我的條碼</div>
          <div>&nbsp;</div>
      </div>
    </section>
    <!-- Main -->
    <section class="main barcode">
      <!-- <a class="button fit rounded" @click.prevent="popCode()"
                ><span class="fS">手動輸入代碼</span></a
            > -->
      <div class="board-wrap">
        <div class="flexH between width">
          <div class="bw-title">會員條碼</div>
          <div class="flexH between w-20 ">
            <a
            :class="{ active: memberCodeType === 'qrcode', icons:true }"
            @click.prevent="memberCodeType = 'qrcode'"
          >
            <font-awesome-icon
              icon="fa-solid fa-qrcode"
              size="xl"
            ></font-awesome-icon>
          </a>
          <a
            :class="{ active: memberCodeType === 'barcode' , icons:true }"
            @click.prevent="memberCodeType = 'barcode'"
          >
            <font-awesome-icon
              icon="fa-solid fa-barcode"
              size="xl"
            ></font-awesome-icon>
          </a>
          </div>
        </div>
        <div class="center py-8">
          <QrcodeVue
            v-if="memberCodeType === 'qrcode'"
            :value="memberCode"
            size="250"
            level="H"
          />
          <barcode v-else-if="!memberCode" value="000000000000000000000" format="code128" width="2" />
          <barcode v-else :value="memberCode" format="code128" width="2">
            {{ memberCode }}
          </barcode>
        </div>
        
      </div>

      <div class="board-wrap">
        <div class="flexH between width">
          <div class="bw-title">載具條碼</div>       
        </div>
        <div class="center py-8">
          <barcode v-if="userCardNo" :value="userCardNo" format="code128" width="2">
            {{ userCardNo }}
          </barcode>
          <div v-else>
            <div class="bw-title2 mb-8">您尚未綁定載具</div>
            <a class="bw-button" @click.prevent="$router.push('/carrier/edit')">點此前往綁定</a>
          </div>
        </div>
        
      </div>
     
    </section>
   
   
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VueBarcode from 'vue-barcode';
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'BarcodePopup',
  components: {
    barcode: VueBarcode,
    QrcodeVue,
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      memberCode: '',
      intervalId: '',
      defaultTimerSec: 300,
      timer: 300,
      codeType: 'qrcode',
      tab: 'member',
      memberCodeType :'qrcode',
      carrierCodeType :'',
    };
  },
  mounted() {    
    this.refreshMemberCode();
    document.body.style.overflow = "hidden";    
    
  },
  beforeDestroy() {
    this.clearTimer();
    document.body.style.overflow = "scroll";
  },
  computed: {
    ...mapState(['user', 'userInfo']),
    userDisplayedName() {
      return this.userInfo.name;
    },
    // userIdNo() {
    //   return this.user?.user?._json
    //     ? this.hideIdentityNumber(this.user.user._json.idno)
    //     : '';
    // },
    userImageUrl() {
      return this.userInfo ? this.userInfo.picture : '';
    },
    userCardNo() {
      return this.userInfo?.cardNo ? this.userInfo?.cardNo.trim() : null;
    },
  },
  methods: {
    hideIdentityNumber(str) {
      let pattern = /(^[a-zA-Z]{1})(\d{7})(\d{2})/g;
      return str.replace(pattern, function (match, start, middle, tail) {
        return start + '*'.repeat(middle.length) + tail;
      });
    },
    displayTimerToMinutes() {
      let mins = Math.floor(this.timer / 60);
      let secs = (this.timer % 60).toString().padStart(2, '00');
      return `${mins}:${secs}`;
    },
    getMemberCode() {
      let config = {
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}/one-time-keys/pay`,
        method: 'POST',
      };
      return this.$http(config);
    },
    clearTimer() {
      clearInterval(this.intervalId);
      this.timer = this.defaultTimerSec;
      this.memberCode = '';
      this.intervalId = '';
    },
    periodRefreshMemberCode() {
      this.clearTimer();
      this.intervalId = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          this.refreshMemberCode();
        }
      }, 1000);
    },
    refreshMemberCode() {
      this.periodRefreshMemberCode();
      this.getMemberCode()
        .then((memberCodeRes) => {
          this.memberCode = memberCodeRes.data.oneTimeKey;
        })
        .catch((memberCodeErr) => {
          console.log('memberCodeErr: ', memberCodeErr);
        });
    },
    closePopup() {
      this.$emit('closePopup');
    },
  },
};
</script>
<style>
.vue-barcode-element {
  width: 100%;
}
</style>
